<template>
	<el-dialog :title="!dataForm.recId ? '单位信息添加' : '单位信息修改'" :close-on-click-modal="false" :visible.sync="visible"
		width="900px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="12" class="col-set_border">
					<el-form-item label="单位名称" prop="companyName" class="item">
						<el-input class="selItemInput" type="input" v-model="dataForm.companyName"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="单位类型" prop="companyKind" class="item">
						<el-select class="selItemInput" v-model="dataForm.companyKind" placeholder="请选择">
							<el-option v-for="item in companyKindList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="备注" prop="remark" class="item">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.remark"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>

			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "enterprise-add-or-update",
	data() {
		return {
			visible: true,
			form: {},
			action: "",
			companyKindList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				recId: "",
				companyName: "",
				companyKind: "",
				remark: ""
			},
			dataRule: {
				companyName: [
					{ required: true, message: "单位名称不能为空！", trigger: "blur" }
				],
				companyKind: [
					{ required: true, message: "单位类型不能为空！", trigger: "blur" }
				]
			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.getCompanyKind();
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				recId: "",
				companyName: "",
				companyKind: "",
				remark: ""
			};
			this.dataForm.id = id ? id : 0;
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/companyinfo/info/" + this.dataForm.id,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
				}
			});
		},
		getCompanyKind() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getCompanyKind",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.companyKindList = data.body;
				}
			});
		},
		dataFormSubmit() {
			if (this.dataForm.companyName == null || this.dataForm.companyName == "") {
				this.$message.error("单位名称不能为空！");
				return;
			}
			if (this.dataForm.companyKind == null || this.dataForm.companyKind == "") {
				this.$message.error("单位类型不能为空！");
				return;
			}
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/companyinfo/save";
					if (this.dataForm.recId && this.dataForm.recId != 0) {
						_url = "/business/companyinfo/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 689px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}
</style>
